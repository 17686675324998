<template>
	<router-view />
</template>

<script>
import mixin from '@/utils/mixinPages'

export default {
	name: 'TablesPage',
	mixins: [mixin],
}
</script>
