import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters({
			local_status: 'merchants/local_status',
		}),
	},
	mounted() {
		if (this.checkDisabled()) this.$router.push('/home/orders/')
	},
	methods: {
		checkDisabled() {
			const blocked = ['STR', 'DSB']

			return blocked.includes(this.local_status) ? true : false
		},
	},
}
